.event {
}

.eventAction {
  font-size: 12px;
  font-weight: 600;
}

.eventList {
  padding: 0 30px;
}

