.root {
  align-items: center;
  column-gap: 50px;
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.closeButton {
  margin-bottom: 30px;
  margin-left: auto;
  width: fit-content;
}

.hamburgerButton {
  display: none;
}

.navLinksMain {
  align-items: center;
  column-gap: 24px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
}

.navLinksMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;
}

.navMenu {
  background-color: var(--foremost-blue);
  box-shadow: 0 0 10px 7px var(--foremost-black-faint-6);
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 24px;
}

.navMenu.showMenu {
  display: block;
}

.title {
  color: var(--foremost-white);
  font-weight: 500;
}

/* .userIcon {
  color: #05a5e1;
  background-color: #edf4fa;
  border-radius: 100%;
  padding: 9px;
  font-size: 12px;
  margin-right: 15px;
} */

/* .dark .userIcon {
  color: var(--foremost-black);
} */

@media only screen and (max-width: 768px) {
  .root {
    padding: 48px 25px;
  }

  .hamburgerButton {
    display: block;
  }

  .navLinksMain {
    display: none;
  }
}

@media print {
  .root {
    display: none;
  }
}
