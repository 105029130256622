.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: #05a5e1;
}

.dark {
  background-color: var(--foremost-black);
}

.content {
  flex-grow: 1;
  position: relative;
}
