.root {
  background: var(--foremost-blue-faint-1);
  border: 1px solid var(--foremost-blue-faint-2);
  border-radius: 8px;
  padding: 20px;
}

.message {
  align-items: center;
  display: flex;
}

.messageText {
  font-size: 14px;
  margin-left: 5px;
}

.stage {
  background: #FFF;
  border: 1px solid #ACB3CB77;
  border-radius: 10px;
  margin-right: 20px;
  padding: 8px;
  width: 160px;
}

.stageLabel {
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 20px;
}

.stageValue {
  font-size: 24px;
  text-align: right;
}

.stages {
  display: flex;
}

@media screen and (max-width: 768px) {
  .stages {
    flex-direction: column;
  }

  .stage {
    margin-bottom: 20px;
    margin-right: 0;
  }
}