.heading1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.heading2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1em;
}