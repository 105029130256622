.status {
  align-items: center;
  display: flex;
  font-size: 12px;
}

.taskStatus {
  font-size: 12px;
  margin: 5px 0 0 0;
}

.taskTitle {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.tile {
  align-items: center;
  background: #FFF;
  border: 1px solid #ACB3CB77;
  border-radius: 10px;
  box-shadow: 5px 5px 1px var(--foremost-blue-faint-2);
  display: flex;
  margin-right: 20px;
  padding: 24px 16px;
}

.tileIcon {
  margin-right: 10px;
}

.tileIcon svg {
  height: 40px;
  width: 40px;
}